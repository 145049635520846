import WebApp from '../../../webapp';

let bitRateAll = [0, 32, 48, 64, 80, 96, 128, 160, 192, 224, 256, 320, 384, 448, 512, 640, 768, 896, 1024, 1280, 1536, 1792, 2048, 4096, 6144, 8192, 10240, 12288, 14336, 16384, 18432, 20480, 22528, 24576, 26624, 28672, 30720, 32768];
/**
 * 函数功能: 根据传入分辨率确定输出确定制式下(pal=== PAL; pal=== NTSC)宽高乘积.
 * 参数说明: fra:  输入分辨率.
*/
export const getWidthH = (fra, VideoStandard) => {
    var pal = VideoStandard;
    var fras = ['D1', 'HD1', 'BCIF', 'CIF', 'QCIF', 'VGA', 'QVGA', 'SVCD', 'QQVGA', 'SVGA', 'XVGA', 'WXGA', 'SXGA', 'WSXGA', 'UXGA', 'WUXGA', 'ND1', '720', '1080', '1_3M', '2.5M', '5_1M', '3M', '1_2M', '1_5M', '8M', '5M', '4M'];

    var whp = ['704*576', '352*576', '704*288', '352*288', '176*144', '640*480', '320*240', '480*480', '160*128', '800*592', '1024*768', '1280*800', '1280*1024', '1600*1024', '1600*1200', '1920*1200', '240*192', '1280*720', '1920*1080', '1280*960', '1872*1408', '2560*1920', '2048*1536', '1216*1024', '1408*1024', '3296*2472', '3744*1408', '2560*1600'];
    var whn = ['704*480', '352*480', '704*240', '352*240', '176*120', '640*480', '320*240', '480*480', '160*128', '800*592', '1024*768', '1280*800', '1280*1024', '1600*1024', '1600*1200', '1920*1200', '240*192', '1280*720', '1920*1080', '1280*960', '1872*1408', '2560*1920', '2048*1536', '1216*1024', '1408*1024', '3296*2472', '3744*1408', '2560*1600'];
    if (fra === '720P') fra = '720';
    if (fra === '1080P') fra = '1080';
    if (fra === '1_3M') fra = '1_3M';
    if (fra === '2_5M') fra = '2.5M';
    var ind = fras.indexOf(fra);
    if (ind < 0) {
        var tp = fra.split('x');
        return tp[0] + '*' + tp[1];
    } else {
        if (pal === 'PAL') {
            return whp[ind];
        } else {
            return whn[ind];
        }
    }
};

/**
 * 从bitRateAll中取一段在能力BitRateOptions范围的数组。
 * @param {Array} BitRateOptions 范围
 * @return {Array} 能力范围内的码流数组
 */
export const getBitRateList = (BitRateOptions) => {
    let range = bitRateAll.filter((item) => item >= BitRateOptions[0] && item <= BitRateOptions[1]);
    return range.sort((a, b) => a - b);
};

/**
 * 重新计算分辨率
 */
export const getFrame = (width, height) => {
    //var fra=(width-0)*(height-0); //HD1 = BCIF?
    let fra = width + '*' + height;
    if (fra === '704*576' || fra === '704*480') return 'D1';
    else if (fra === '352*576' || fra === '352*480') return 'HD1';
    else if (fra === '704*288' || fra === '704*240') return 'BCIF';
    else if (fra === '352*288' || fra === '352*240') return 'CIF';
    else if (fra === '176*144' || fra === '176*120') return 'QCIF';
    else if (fra === '640*480') return 'VGA';
    else if (fra === '320*240') return 'QVGA';
    else if (fra === '480*480') return 'SVCD';
    else if (fra === '160*128') return 'QQVGA';
    else if (fra === '800*592') return 'SVGA';
    else if (fra === '1024*768') return 'XVGA';
    else if (fra === '1280*800') return 'WXGA';
    else if (fra === '1280*1024') return 'SXGA';
    else if (fra === '1600*1024') return 'WSXGA';
    else if (fra === '1600*1200') return 'UXGA';
    else if (fra === '1920*1200') return 'WUXGA';
    else if (fra === '240*192') return 'ND1';
    else if (fra === '1280*720') return '720P';
    else if (fra === '1920*1080') return '1080P';
    else if (fra === '1280*960') return '1_3M';
    else if (fra === '1872*1408') return '2.5M';
    else if (fra === '3744*1408') return '5M';
    else if (fra === '2560*1920') return '5_1M';
    else if (fra === '2048*1536') return '3M';
    else if (fra === '2560*1600') return '4M';
    //else if(fra==='1216*1024') return '1_2M';
    //else if(fra==='1408*1024') return '1_5M';
    //else if(fra==='3296*2472') return '8M';
    else return width + 'x' + height;
};

/**
 * 根据当前码流范围及当前码流值，计算出参考码流值
 * 注：依赖于ischangebitrate
 * @param {Array} BitRateOptions 码流范围
 * @param {Number} BitRate 参考码流值
 * @param {Boolean} isUseList 是否优化使用下拉列表中的值
 * @return {Number} BitRate
 */
export const getSuggestBitRate = (BitRateOptions, BitRate, isUseList) => {
    // 码流范围内给定值列表
    var BitRateList = getBitRateList(BitRateOptions);
    // 范围内的中间值
    var middleValue = parseInt((BitRateOptions[0] + BitRateOptions[1]) / 2);
    if (BitRate >= BitRateOptions[0] && BitRate <= BitRateOptions[1]) {
        return BitRate;
    } else {
        for (var i = 0; i < BitRateList.length; i++) {
            if (BitRateList[i] >= middleValue) return BitRateList[i];
        }
        if (isUseList) return middleValue;
        else return BitRateList[i - 1];
    }
};

/**
 * [fixRegion description]根据旋转和镜像的情况，矫正配置中坐标的实际值
 * @param  {[type]} r [description]配置中的坐标
 * @return {[type]} n    [description]矫正后的坐标
 */
export const fixRegion = (r, TransformRule) => {
    var n = [],
        nn = [],
        flip = TransformRule.Flip,
        rotate90 = TransformRule.Rotate90,
        mirror = TransformRule.Mirror;

    if (rotate90 === 1) {
        n[0] = 8191 - r[1];
        n[1] = r[0];
        n[2] = 8191 - r[3];
        n[3] = r[2];
    } else if (rotate90 === 2) {
        n[0] = r[1];
        n[1] = 8191 - r[0];
        n[2] = r[3];
        n[3] = 8191 - r[2];
    } else if (flip) {
        n[0] = r[0];
        n[1] = 8191 - r[1];
        n[2] = r[2];
        n[3] = 8191 - r[3];
    } else {
        n[0] = r[0];
        n[1] = r[1];
        n[2] = r[2];
        n[3] = r[3];
    }

    if (n[0] > n[2]) {
        var n0 = n[0];
        n[0] = n[2];
        n[2] = n0;
    }
    if (n[1] > n[3]) {
        var n1 = n[1];
        n[1] = n[3];
        n[3] = n1;
    }
    if (mirror) {
        if (rotate90 === 0) {
            nn[0] = 8191 - n[2];
            nn[1] = n[1];
            nn[2] = 8191 - n[0];
            nn[3] = n[3];
        } else {
            nn[0] = n[0];
            nn[1] = 8191 - n[3];
            nn[2] = n[2];
            nn[3] = 8191 - n[1];
        }
        return nn;
    }
    return n;
};

/**
 * [unfixRegion description]根据旋转和镜像的情况,将控件回调的坐标值，转化为正确的配置
 * @param  {[type]} r [description]控件回调矩形
 * @return {[type]} region  [description]
 */
export const unfixRegion = (r, TransformRule) => {
    var n = [],
        nn = [],
        flip = TransformRule.Flip,
        rotate90 = TransformRule.Rotate90,
        mirror = TransformRule.Mirror;

    if (rotate90 === 1) {
        n[0] = r[1];
        n[1] = 8191 - r[0];
        n[2] = r[3];
        n[3] = 8191 - r[2];
    } else if (rotate90 === 2) {
        n[0] = 8191 - r[1];
        n[1] = r[0];
        n[2] = 8191 - r[3];
        n[3] = r[2];
    } else if (flip) {
        n[0] = r[0];
        n[1] = 8191 - r[1];
        n[2] = r[2];
        n[3] = 8191 - r[3];
    } else {
        n[0] = r[0];
        n[1] = r[1];
        n[2] = r[2];
        n[3] = r[3];
    }

    if (n[0] > n[2]) {
        var n0 = n[0];
        n[0] = n[2];
        n[2] = n0;
    }
    if (n[1] > n[3]) {
        var n1 = n[1];
        n[1] = n[3];
        n[3] = n1;
    }

    if (mirror) {
        nn[0] = 8191 - n[2];
        nn[1] = n[1];
        nn[2] = 8191 - n[0];
        nn[3] = n[3];
        return nn;
    }
    return n;
};

/**
 * 判断一维字符串数组是否相等
 * @param {Array} arr1
 * @param {*} arr2
 */
export const equalArray = (arr1, arr2) => {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
};

/**
 * 判断value是否是纯数字
 * @param {*} value
 */
export const testNumber = (value) => {
    return /^[0-9]+$/.test(value);
};